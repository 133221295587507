import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results-unterstufe',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {

  constructor( ) {
    
    
  }

 ngOnInit() {
  
  
 // Name, Aufgaben und Punkte von sessionStorage verwenden
   document.getElementById("name").innerHTML = sessionStorage.getItem('Name');
   document.getElementById("aufgaben").innerHTML = sessionStorage.getItem('aktuelle Nummer');
   document.getElementById("punkte").innerHTML = sessionStorage.getItem('aktuelle Punktzahl');
  
     
  
   // Datum und Uhrzeit generieren
   let year = new Date();
     
   var  months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
   var d = new Date();
   var monthName=months[d.getMonth()];

   let date = new Date();
   let date1 = date.getDate();

   let hours = new Date();
   let hour1 = hours.getHours();

   let minutes = new Date();
   let minute1 = minutes.getMinutes();

   // Datum und Uhrzeit auf html zuordnen
   document.getElementById('zeit1').innerHTML = date1 +". " + monthName + " " + year.getFullYear();
   document.getElementById('zeit2').innerHTML = hour1 +" Uhr " + minute1;
   // document.getElementById("counter").innerHTML = sessionStorage.getItem('counter');

   // direkt zum Ausdruck
   // Optionen wären Wahlfelder Druck, E-Mail, PDF
 }

public startPrint(){
   var node = document.querySelector('#print') as HTMLElement;
   node.style.display = 'none';
   print()
   var node = document.querySelector('#print') as HTMLElement;
   node.style.display = 'block';
 }

}

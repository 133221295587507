import { Component, OnInit } from '@angular/core';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { Router } from '@angular/router';
import { BlockScrollStrategy } from '@angular/cdk/overlay';


@Component({
  selector: 'app-reihen2',
  templateUrl: './reihen2.component.html',
  styleUrls: ['./reihen2.component.scss']
})
export class Reihen2Component implements OnInit {

  public task1: ExerciseTask;
  public task2: ExerciseTask;
  public task3: ExerciseTask;
  public task4: ExerciseTask;
  public task5: ExerciseTask;
  public task6: ExerciseTask;
  public task7: ExerciseTask;
  public task8: ExerciseTask;
  public task9: ExerciseTask;
  public task10: ExerciseTask;

  public textfield1: string;
  public textfield2: string;
  public textfield3: string;
  public textfield4: string;
  public textfield5: string;
  public textfield6: string;
  public textfield7: string;
  public textfield8: string;
  public textfield9: string;
  public textfield10: string;

  public resultat1: number;
  public resultat2: number;
  public resultat3: number;
  public resultat4: number;
  public resultat5: number;
  public resultat6: number;
  public resultat7: number;
  public resultat8: number;
  public resultat9: number;
  public resultat10: number;

  public ergebnis1: number;
  public ergebnis2: number;
  public ergebnis3: number;
  public ergebnis4: number;
  public ergebnis5: number;
  public ergebnis6: number;
  public ergebnis7: number;
  public ergebnis8: number;
  public ergebnis9: number;
  public ergebnis10: number;

  public actualTaskNumber: number;
  public actualPunkte: number;

  public reihe: number;
    
     
  constructor(
    private router: Router
  ) {  }

  ngOnInit() {
  
  this.actualTaskNumber = 0;
  this.actualPunkte = 0;
  this.textfieldleer ();
  this.feedbackleer();
  this.reihe2();
 
     
  }

  public textfieldleer () {
    this.textfield1 = '';
    this.textfield2 = '';
    this.textfield3 = '';
    this.textfield4 = '';
    this.textfield5 = '';
    this.textfield6 = '';
    this.textfield7 = '';
    this.textfield8 = '';
    this.textfield9 = '';
    this.textfield10 = '';
  }

  public feedbackleer () {
    var node = document.querySelector('#feedback1r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback1f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback2r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback2f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback3r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback3f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback4r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback4f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback5r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback5f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback6r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback6f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback7r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback7f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback8r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback8f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback9r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback9f') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback10r') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#feedback10f') as HTMLElement;
    node.style.display = 'none';

  }
  
  public createExercise() {
    
    var node = document.querySelector('#circle') as HTMLElement;
        
    this.task1 = new ExerciseTask(1, 'mal' ,this.reihe);
    this.ergebnis1 = 1 * this.reihe;
    this.task2 = new ExerciseTask(2, 'mal' ,this.reihe);
    this.ergebnis2 = 2 * this.reihe;
    this.task3 = new ExerciseTask(3, 'mal' ,this.reihe);
    this.ergebnis3 = 3 * this.reihe;
    this.task4 = new ExerciseTask(4, 'mal' ,this.reihe);
    this.ergebnis4 = 4 * this.reihe;
    this.task5 = new ExerciseTask(5, 'mal' ,this.reihe);
    this.ergebnis5 = 5 * this.reihe;
    this.task6 = new ExerciseTask(6, 'mal' ,this.reihe);
    this.ergebnis6 = 6 * this.reihe;
    this.task7 = new ExerciseTask(7, 'mal' ,this.reihe);
    this.ergebnis7 = 7 * this.reihe;
    this.task8 = new ExerciseTask(8, 'mal' ,this.reihe);
    this.ergebnis8 = 8 * this.reihe;
    this.task9 = new ExerciseTask(9, 'mal' ,this.reihe);
    this.ergebnis9 = 9 * this.reihe;
    this.task10 = new ExerciseTask(10, 'mal' ,this.reihe);
    this.ergebnis10 = 10 * this.reihe;
  }
  

  public reihe2()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 2;
    this.createExercise();
  }
  public reihe3()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 3;
    this.createExercise();
  }
  public reihe4()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 4;
    this.createExercise();
  }
  public reihe5()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 5;
    this.createExercise();
  }
  public reihe6()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 6;
    this.createExercise();
  }
  public reihe7()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 7;
    this.createExercise();
  }
  public reihe8()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 8;
    this.createExercise();
    }
  public reihe9()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 9;
    this.createExercise();
    }
  public reihe10()   {
    this.actualPunkte = 0;
    this.textfieldleer ();
    this.feedbackleer();
    this.reihe = 10;
    this.createExercise();
  }

  public level1(){
    this.router.navigate(['/reihen1']);
  }
  
  public level2(){
    this.router.navigate(['/reihen2']);
  }
  
  public level6(){
    this.router.navigate(['/reihen6']);
  }

    
  public buttonClick() {

    if (parseFloat(this.textfield1) === this.ergebnis1) {
          var node = document.querySelector('#feedback1r') as HTMLElement;
          node.style.display = 'block';
          var node = document.querySelector('#feedback1f') as HTMLElement;
          node.style.display = 'none';
        } else {
          var node = document.querySelector('#feedback1f') as HTMLElement;
          node.style.display = 'block';
          var node = document.querySelector('#feedback1r') as HTMLElement;
          node.style.display = 'none';
      }

    if (parseFloat(this.textfield2) === this.ergebnis2) {
      var node = document.querySelector('#feedback2r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback2f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback2f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback2r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield3) === this.ergebnis3) {
      var node = document.querySelector('#feedback3r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback3f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback3f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback3r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield4) === this.ergebnis4) {
      var node = document.querySelector('#feedback4r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback4f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback4f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback4r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield5) === this.ergebnis5) {
      var node = document.querySelector('#feedback5r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback5f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback5f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback5r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield6) === this.ergebnis6) {
      var node = document.querySelector('#feedback6r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback6f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback6f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback6r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield7) === this.ergebnis7) {
      var node = document.querySelector('#feedback7r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback7f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback7f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback7r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield8) === this.ergebnis8) {
      var node = document.querySelector('#feedback8r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback8f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback8f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback8r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield9) === this.ergebnis9) {
      var node = document.querySelector('#feedback9r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback9f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback9f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback9r') as HTMLElement;
      node.style.display = 'none';
      }

    if (parseFloat(this.textfield10) === this.ergebnis10) {
      var node = document.querySelector('#feedback10r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback10f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback10f') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback10r') as HTMLElement;
      node.style.display = 'none';
      }
    }
} 

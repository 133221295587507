import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {  }
  public buttonReihen() {
    this.router.navigate(['/reihen2']);

  }

  public buttonLevel() {
    this.router.navigate(['/levels']);

  }
  
  public buttonTestclass() {
    this.router.navigate(['/configurationtest']);

  }public buttonExercise() {
    this.router.navigate(['/exercise']);

  }
  public buttonConfiguration() {
    this.router.navigate(['/configuration']);

  }
  public buttonConfigurationUS() {
    this.router.navigate(['/configuration-unterstufe']);

  }

  public buttonExerciseUS() {
    this.router.navigate(['/exercise-unterstufe']);

  }

  public buttonS2a_S2b() {
    this.router.navigate(['/testclasss2']);

  }

}

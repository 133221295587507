import { Component, OnInit, ViewChild } from '@angular/core';
import { FormType } from '../form-type';
import { Router, Params } from '@angular/router';
import { Tests2Service } from '../../services/tests2.service';
 

@Component({
  selector: 'app-configurationtests2',
  templateUrl: './configurationtests2.component.html',
  styleUrls: ['./configurationtests2.component.scss']
})
export class Configurationtests2Component implements OnInit {

  public values: FormType[];

  constructor(
    private router: Router,
    private tests2Service: Tests2Service) { }


  ngOnInit() {
    this.values = this.tests2Service.getConfig();
  }

 
  public buttonClick() {
    this.tests2Service.saveConfig(this.values);
    // console.log('ValuesBi: ' + JSON.stringify(this.values));

    this.router.navigate(['/testclasss2']);
    
  }

}



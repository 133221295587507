import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseConfig } from './../interfaces/exercise-config';
import { FormType } from './../configuration/form-type';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})

export class TestService {

  private defaultValues: FormType[];
  
    constructor(private http: HttpClient) {
      this.defaultValues = [];
      this.defaultValues.push(new FormType('Wie lang soll der Test dauern, in Minuten?', "10"));
      this.defaultValues.push(new FormType('Gib hier deinen Namen ein', "NoName"));
      this.defaultValues.push(new FormType('Wie viele Punkte soll es für eine richtige Aufgabe geben?', "2"));
      this.defaultValues.push(new FormType('Wie viele Punkte sollen für eine falsche Aufgabe abgezogen werden?', "1"));
      this.defaultValues.push(new FormType('Wie viele Punkte sind für die Note 6 notwendig?', "120"));
      this.defaultValues.push(new FormType('Wie viele Punkte sind für die Note 4 notwendig?', "70"));
      this.defaultValues.push(new FormType('Wie gross sollen die Summanden mindestens sein?', "8"));
      this.defaultValues.push(new FormType('Wie gross dürfen die Summanden höchstens sein?', "180"));
      this.defaultValues.push(new FormType('Wie gross soll der Minuend mindestens sein?', "6"));
      this.defaultValues.push(new FormType('Wie gross darf der Minuend höchstens sein?', "130"));
      this.defaultValues.push(new FormType('Wie gross darf der Subtrahend mindestens sein?', "4"));
      this.defaultValues.push(new FormType('Wie gross darf der Subtrahend höchstens sein?', "40"));
      this.defaultValues.push(new FormType('Wie gross dürfen die Faktoren mindestens sein?', "3"));
      this.defaultValues.push(new FormType('Wie gross dürfen die Faktoren höchstens sein?', "12"));
      this.defaultValues.push(new FormType('Wie gross darf der Divisor mindestens sein?', "2"));
      this.defaultValues.push(new FormType('Wie gross darf der Divisor höchstens sein?', "18"));
      this.defaultValues.push(new FormType('alle(4), ohne Div(3), Add/Subt(2), nur Add(1), nur Subt(5), nur Mult(6), nur Div(7)',  "4"));
  }

  public getExercise(): Promise<ExerciseConfig> {
    return this.http.get<ExerciseConfig>('/assets/exercise-config.json').toPromise();
  }

  public saveConfig(values: FormType[]) {
    sessionStorage.setItem('valuesAllgemein', JSON.stringify(values));
    }

  public getConfig(): FormType[] {
    // tslint:disable-next-line: prefer-const
    let values = sessionStorage.getItem('valuesAllgemein');
    if (!isNullOrUndefined(values) && values.length > 0) {
      return JSON.parse(values) as FormType[];
    }
    return this.defaultValues;
    
  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resultstests2',
  templateUrl: './resultstests2.component.html',
  styleUrls: ['./resultstests2.component.scss']
})
export class Resultstests2Component implements OnInit {

  public name: string;
  public anzahlAufgaben: string;
  public definitivePunkte: string;
  public note: string;

  constructor( ) {
    
  }

 ngOnInit() {  
  
  // Name, Aufgaben und Punkte von sessionStorage verwenden
  // document.getElementById("name").innerHTML = sessionStorage.getItem('Name');
  // document.getElementById("aufgaben").innerHTML = sessionStorage.getItem('aktuelle Nummer');
  // document.getElementById("punkte").innerHTML = sessionStorage.getItem('punkte');
  // document.getElementById("note").innerHTML = sessionStorage.getItem('note');  
 
  document.getElementById("name").innerHTML = localStorage.getItem('Name');
  document.getElementById("aufgaben").innerHTML = localStorage.getItem('aktuelle Nummer');
  document.getElementById("punkte").innerHTML = localStorage.getItem('punkte');
  document.getElementById("note").innerHTML = localStorage.getItem('note');

 
 
  // Datum und Uhrzeit generieren
  let year = new Date();
     
  var  months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  var d = new Date();
  var monthName=months[d.getMonth()];

  let date = new Date();
  let date1 = date.getDate();

  let hours = new Date();
  let hour1 = hours.getHours();

  let minutes = new Date();
  let minute1 = minutes.getMinutes();

  // Datum und Uhrzeit auf html zuordnen
  document.getElementById('zeit1').innerHTML = date1 +". " + monthName + " " + year.getFullYear();
  document.getElementById('zeit2').innerHTML = hour1 +" Uhr " + minute1;
   

   // direkt zum Ausdruck
   // Optionen wären Wahlfelder Druck, E-Mail, PDF
 }

 public startPrint(){
   var node = document.querySelector('#print') as HTMLElement;
   node.style.display = 'none';
   print()
   var node = document.querySelector('#print') as HTMLElement;
   node.style.display = 'block';
 }

}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExerciseComponent } from './exercise/normal/exercise.component';
import { FormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';



import { HttpClientModule } from '@angular/common/http';
import { ConfigurationComponent } from './configuration/normal/configuration.component';
import { HomeComponent } from './home/home.component';
import { ConfigurationUnterstufeComponent } from './configuration/configuration-unterstufe/configuration-unterstufe.component';
import { TestclassComponent } from './exercise/exercisetest/exercisetest.component';
import { ResultsTestComponent } from './results/resultstest/resultstest.component';
import { ConfigurationtestComponent } from './configuration/configurationtest/configurationtest.component';
import { ExerciseUnterstufeComponent } from './exercise/exercise-unterstufe/exercise-unterstufe.component';
import { ResultsComponent } from './results/normal/results.component';


import { Configurationtests2Component } from './configuration/configurationtests2/configurationtests2.component';
import { Testclasss2Component } from './exercise/exercisetests2/exercisetests2.component';
import { SettingstestComponent } from './settings/settingstest/settingstest.component';
import { SettingsnormalComponent } from './settings/settingsnormal/settingsnormal.component';
import { Reihen2Component } from './reihen/reihen2/reihen2.component';
import { Reihen6Component } from './reihen/reihen6/reihen6.component';
import { InfoReihen6Component } from './results/reihen/reihen6/inforeihen6.component';
import { Reihen1Component } from './reihen/reihen1/reihen1.component';
import { ZumtestenComponent } from './reihen/zumtesten/zumtesten.component';
import { Exercistests3Component } from './exercise/exercistests3/exercistests3.component';
import { Resultstests2Component } from './results/resultstests2/resultstests2.component';
import { Resultstests3Component } from './results/resultstests3/resultstests3.component';
import { Settingstests2Component } from './settings/settingstests2/settingstests2.component';
import { Settingstests3Component } from './settings/settingstests3/settingstests3.component';

// alle Variante Counter
import { NormalComponent } from './counter/normal/normal.component';
import { UnterstufeComponent } from './counter/unterstufe/unterstufe.component';
import { Tests2Component } from './counter/tests2/tests2.component';
import { Tests3Component } from './counter/tests3/tests3.component';
import { TestComponent } from './counter/test/test.component';
import { Configurationtestr2Component } from './configuration/configurationtestr2/configurationtestr2.component';
import { Testr2Component } from './counter/testr2/testr2.component';
import { Exercisetestr2Component } from './exercise/exercisetestr2/exercisetestr2.component';
import { Resultstestr2Component } from './results/resultstestr2/resultstestr2.component';
import { Settingstestr2Component } from './settings/settingstestr2/settingstestr2.component';
import { LevelsComponent } from './levels/levels.component';




@NgModule({
  declarations: [
    AppComponent,
    ExerciseComponent,
    ConfigurationComponent,
    HomeComponent,
    ConfigurationUnterstufeComponent,
    TestclassComponent,
    ResultsTestComponent,
    ConfigurationtestComponent,
    ExerciseUnterstufeComponent,
    ResultsComponent,
    Configurationtests2Component,
    Testclasss2Component,
    SettingstestComponent,
    SettingsnormalComponent,
    Reihen2Component,
    Reihen6Component,
    InfoReihen6Component,
    Reihen1Component,
    ZumtestenComponent,
    Exercistests3Component,
    Resultstests2Component,
    Resultstests3Component,
    Settingstests2Component,
    Settingstests3Component,

    // alle Varianten Counter
    NormalComponent,
    UnterstufeComponent,
    Tests2Component,
    Tests3Component,
    TestComponent,
    Configurationtestr2Component,
    Testr2Component,
    Exercisetestr2Component,
    Resultstestr2Component,
    Settingstestr2Component,
    LevelsComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    Ng5SliderModule,
    DragDropModule,

    

  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { Router } from '@angular/router';



@Component({
  selector: 'app-exercise-unterstufe',
  templateUrl: './exercise-unterstufe.component.html',
  styleUrls: ['./exercise-unterstufe.component.scss']
})
export class ExerciseUnterstufeComponent implements OnInit {

  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public actualTaskNumber: number;
  public punkte: number;
  public actualPunkte: number;
  public numberOperation: number;
  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string
  public counter: number;
  public name: string;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;

  public results: [];
    
  
  constructor(
    private router: Router) { }
		
  
    ngOnInit() {
    this.actualTaskNumber = 0;
    this.actualPunkte = 0;
    this.textfield = '';
    this.counter = 100000; //allenfalls noch ändern, ngif oder...
    this.createExerciseWrapper();
	}	


	// Name sollte eingetragen sein; wird im Moment nicht kontrolliert
  // Name wird im sessionStorage gespeichert
  // counter wird aktiviert, falls der Wert unter 100'000 ist; könnte noch eleganter gelöst werden
  public startTest() {
    sessionStorage.setItem('Name', this.name);
    this.startBereichAusblenden();
    this.bereichRechnungEinblenden();
    this.bereichResultatEinblenden();
    if (this.counter < 100000) {
      this.counter = this.counter * 60;
      this.counterStart();
      this.startBalken();
    } else {
      this.counterNein();
    }
  }
  
  // Bereich Start ausblenden und Abschlussbutton einblenden für Start
  private startBereichAusblenden() {
    var node = document.querySelector('#start') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#start1') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#start2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#abschluss') as HTMLElement;
    node.style.display = 'block';
  }

  // Bereich Rechnung einblenden
  private bereichRechnungEinblenden() {
    var node = document.querySelector('#rechnung') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#rechnung1') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#rechnung2') as HTMLElement;
    node.style.visibility = 'visible';
  }

  // Bereich Resultat einblenden
  private bereichResultatEinblenden() {
    var node = document.querySelector('#resultat') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#resultat1') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#resultat2') as HTMLElement;
    node.style.visibility = 'visible';
    var node = document.querySelector('#resultat3') as HTMLElement;
    node.style.visibility = 'visible';
  }

  // falls jemand auf Counter nein clickt
  public counterNein() {
    
    var node = document.querySelector('#counterWahl') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#counterWahl') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#counterTime1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#counterTime2') as HTMLElement;
    node.style.visibility = 'hidden';
    }
  
  // falls Counter Ja gewählt wird
  public counterTime() {
    this.counter = 5;
    var node = document.querySelector('#counterWahl') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#counterWahl') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#counterTime1') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#counterTime2') as HTMLElement;
    node.style.visibility = 'visible';
    }

  
  // Countdown starten
  public counterStart() {

    var node = document.querySelector('#counterTime1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#counterTime2') as HTMLElement;
    node.style.visibility = 'hidden';
    
    var node = document.querySelector('#counterEbene') as HTMLElement;
    node.style.display = 'block';
    var node = document.querySelector('#counter') as HTMLElement;
    node.style.visibility = 'visible';

    var i = this.counter;
    var myinterval = setInterval(() => { 
      // Umrechnung der Sekunden in Minuten und Sekunden
      var  timenew = Math.floor(i / 60 )+' : '+Math.floor((i % 60));
      document.getElementById("counter").innerHTML = timenew;
      if (i === 0) {
        clearInterval(myinterval );
        this.router.navigate(['/results']);
      }
      else {
        i--;
      }
    }, 1000);
  }

  // Countdown Balken starten, Progress negativ 
public startBalken() {

  var node = document.querySelector('#balken') as HTMLElement;
  node.style.visibility = 'visible';

  var i = 0;
  if (i == 0) {
    var elem = document.getElementById("balken");
    var width = 100;
    var faktor = this.counter/100*1000;
    var id = setInterval(frame, faktor);
    function frame() {
      if (width <=0) {
        clearInterval(id);
       i = 0 ;
      } else {
        width--;
        elem.style.width = width + "%";
        var node = document.querySelector('#balken') as HTMLElement;
        node.style.display = 'block';
        }
      }
    }
  }
  

  // hier wird die nächste Aufabe erstellt, mit Wrapper
  private createExerciseWrapper() {
    let configurationUS = JSON.parse(localStorage.getItem('configurationUS'));
           
    this.createExercise(configurationUS[0], configurationUS[1], configurationUS[2], configurationUS[3], configurationUS[4], configurationUS[5], configurationUS[6], configurationUS[7], configurationUS[8]);
  }
    
  // hier wird die Aufabe erstellt, zuerst Wahl der Operation, dann die Aufgabe per Zufallsgenerator erstellt
  private createExercise(numberOperation: number, summandMin: number, summandMax: number, minuendMin: number, minuendMax: number, faktorMin: number, faktorMax: number, divisorMin: number, divisorMax: number) {
      
    // Zuordnung aller Variablen, damit sie in anderen Blöcken verwendet werden können
    // der Subtrahend wird bei der Variante Unterstufe Minuend : 2 berechnet, um bei den Einstellungen einfach zu bleiben!!
    this.numberOperation = numberOperation;
    this.summandMin = summandMin;
    this.summandMax = summandMax;
    this.minuendMin = minuendMin;
    this.minuendMax = minuendMax;
    this.subtrahendMin = minuendMin/2;
    this.subtrahendMax = minuendMax/2;
    this.faktorMin = faktorMin;
    this.faktorMax = faktorMax;
    this.divisorMin = divisorMin;
    this.divisorMax = divisorMax;
   
    
    // console.log('Operation' + numberOperation);
    // 1 für Addition, 2 für Subtraktion, 3 für Multiplikation 4 für Divsion
    let operation = Math.round(Math.random() * numberOperation + 0.5);
    
    // nach Entscheid der Operation zwischen 1 und 4 werden je nach Operation zwei Zufallszahlen gebildet
    // bei der Subtraktion wird verhindert, dass das Resultat negativ wird (könnte noch als Option eingebaut werden )
    // bei der Division wird zuerst der Divisor gebildet, welcher am Ende mit dem Dividenden multipliziert wird, damit es eine Ganzzahl als Ergebnis gibt
    switch (operation) {
      case 1:
      let ersterSummand = Math.round(Math.random() * (summandMax - summandMin)) + summandMin;
      let zweiterSummand = Math.round(Math.random() * (summandMax - summandMin)) + summandMin;
        this.task = new ExerciseTask(ersterSummand, '+', zweiterSummand);
        this.ergebnis = ersterSummand + zweiterSummand;
      break;
      
      case 2:
        let minuend = Math.round(Math.random() * (minuendMax - minuendMin)) + minuendMin;
        let subtrahend = Math.round(Math.random() * (this.subtrahendMax - this.subtrahendMin)) + this.subtrahendMin;
        if (subtrahend > minuend) {
          let zwischenSpeicher = minuend;
          minuend = subtrahend;
          subtrahend = zwischenSpeicher;
        }
        this.task = new ExerciseTask(minuend, '-', subtrahend);
        this.ergebnis = minuend - subtrahend;
      break;
      
      case 3:
        let ersterFaktor = Math.round(Math.random() * (faktorMax - faktorMin) ) + faktorMin;
        let zweiterFaktor = Math.round(Math.random() * (faktorMax - faktorMin)) + faktorMin;
        this.task = new ExerciseTask(ersterFaktor, '*', zweiterFaktor);
        this.ergebnis = ersterFaktor * zweiterFaktor;
      break;
      
      case 4:
        let divisor = Math.round(Math.random() * (divisorMax - divisorMin)) + divisorMin;
        let dividend = Math.round(Math.random() * (divisorMax - divisorMin)) * divisor;
        this.task = new ExerciseTask(dividend, ':', divisor);
        this.ergebnis = dividend / divisor;
      break;
      
      default:
        alert("Keine Übereinstimmung");
    }
    
        
    // notwendige Werte werden im sessionStorage gespeichert
    sessionStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
    sessionStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
    
                   
    // notwendige Werte werden im localStorage gespeichert
    localStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
    localStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
             
  }

  // hier wird nach der Eingabe des Ergebnisses dieses nach click oder Return kontrolliert, die TaskNumber um 1 erhöht, und die nächste Aufgabe aufgerufen
  // zudem werden je nachdem Pluspunkte oder Minuspunkte verrechnet und die aktuelle Note neu berechnet
  // es wird ein Feedback gegeben, dies über 1,5 Sekunden, grün bei richtig gelöster Aufgabe, rot bei falsch gelöster Aufgabe
  public buttonClick() {
    this.lastArg1 = this.task.arg1;
	  this.lastOperand = this.task.operand;
	  this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
    if (parseFloat(this.textfield) === this.ergebnis) {
      this.actualPunkte = this.actualPunkte  + 1;
      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig + 1 Punkt';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualPunkte = this.actualPunkte +0;
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.feedback = 'Falsch 0 Punkte!';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';
        setTimeout(this.ausblendenFeedback,1500);
      }
    this.createExerciseWrapper();
    this.textfield = '';
  }

  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  private ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }

  public toResults() {
		let results = [
			this.numberOperation, 
			this.summandMin, 
      this.summandMax,
      this.minuendMin, 
			this.minuendMax,
			this.subtrahendMin,
			this.subtrahendMax,
			this.faktorMin,
			this.faktorMax,
			this.divisorMin,
			this.divisorMax,
		];

		//	console.log('Konfiguration US: ' + configurationUS);
		sessionStorage.setItem("results", JSON.stringify(results));
		localStorage.setItem("results", JSON.stringify(results));		
		this.router.navigate(['/results']);
	
	  }
// Ende class Exercise-Unterstufe
}

export class ExerciseTask {
    constructor(arg1: number, operand: string, arg2: number) {
        this.arg1 = arg1;
        this.operand = operand;
        this.arg2 = arg2 ;
    }

    public arg1: number;
    public operand: string;
    public arg2: number;
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragExit, CdkDragEnter } from '@angular/cdk/drag-drop';



@Component({
  selector: 'app-reihen1',
  templateUrl: './reihen1.component.html',
  styleUrls: ['./reihen1.component.scss']
})
export class Reihen1Component implements OnInit {

  public faktor1: number;
  public reihe: number;
  public rechnungen: any []; 
  public resultate: any []; 
  
 

  constructor(
    private router: Router) {    }

  ngOnInit() { 
    
    
   }
  

  



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.resultate, event.previousIndex, event.currentIndex);
    this.resultsControl();
    
  }

  public aufgaben() {
    this.faktor1 = 1; 
    this.rechnungen = [
    this.faktor1 + ' mal '+ this.reihe + ' = ',
    this.faktor1+1 + ' mal '+ this.reihe + ' = ',
    this.faktor1+2 + ' mal '+ this.reihe + ' = ',
    this.faktor1+3 + ' mal '+ this.reihe + ' = ',
    this.faktor1+4 + ' mal '+ this.reihe + ' = ',
    this.faktor1+5 + ' mal '+ this.reihe + ' = ',
    this.faktor1+6 + ' mal '+ this.reihe + ' = ',
    this.faktor1+7 + ' mal '+ this.reihe + ' = ',
    this.faktor1+8 + ' mal '+ this.reihe + ' = ',
    this.faktor1+9 + ' mal '+ this.reihe + ' = ',
  ];

  this.resultate = [
    this.faktor1 * this.reihe,
    (this.faktor1+1) * this.reihe,
    (this.faktor1+2) * this.reihe,
    (this.faktor1+3) * this.reihe,
    (this.faktor1+4) * this.reihe,
    (this.faktor1+5) * this.reihe,
    (this.faktor1+6) * this.reihe,
    (this.faktor1+7) * this.reihe,
    (this.faktor1+8) * this.reihe,
    (this.faktor1+9) * this.reihe,
  ];
}

public reihe2()   {
  this.reihe = 2;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';

}
public reihe3()   {
  this.reihe = 3;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';

}
public reihe4()   {
  this.reihe = 4;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
 
}
public reihe5()   {
  this.reihe = 5;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
}
public reihe6()   {
  this.reihe = 6;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
}
public reihe7()   {
  this.reihe = 7;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
}
public reihe8()   {
  this.reihe = 8;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
  }
public reihe9()   {
  this.reihe = 9;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
  }
public reihe10()   {
  this.reihe = 10;
  this.aufgaben();
  this.shuffleResultate();
  var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'hidden';
  var node = document.querySelector('#info') as HTMLElement;
  node.style.visibility = 'hidden';
}


  public resultsControl () {
    // Resultat1
    var node = document.querySelector('#questionDiv') as HTMLElement;
  node.style.visibility = 'visible';
    if (this.resultate[0] === (this.faktor1 * this.reihe)) {
      var node = document.querySelector('#feedback1r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback1f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback1r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback1f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat2
    if (this.resultate[1] === ((this.faktor1+1) * this.reihe)) {
      var node = document.querySelector('#feedback2r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback2f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback2r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback2f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat3
    if (this.resultate[2] === ((this.faktor1+2) * this.reihe)) {
      var node = document.querySelector('#feedback3r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback3f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback3r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback3f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat4
    if (this.resultate[3] === ((this.faktor1+3) * this.reihe)) {
      var node = document.querySelector('#feedback4r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback4f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback4r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback4f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat5
    if (this.resultate[4] === ((this.faktor1+4) * this.reihe)) {
      var node = document.querySelector('#feedback5r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback5f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback5r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback5f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat6
    if (this.resultate[5] === ((this.faktor1+5) * this.reihe)) {
      var node = document.querySelector('#feedback6r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback6f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback6r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback6f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat7
    if (this.resultate[6] === ((this.faktor1+6) * this.reihe)) {
      var node = document.querySelector('#feedback7r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback7f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback7r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback7f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat8
    if (this.resultate[7] === ((this.faktor1+7) * this.reihe)) {
      var node = document.querySelector('#feedback8r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback8f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback8r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback8f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat9
    if (this.resultate[8] === ((this.faktor1+8) * this.reihe)) {
      var node = document.querySelector('#feedback9r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback9f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback9r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback9f') as HTMLElement;
      node.style.display = 'block';
    }
    // Resultat10
    if (this.resultate[9] === ((this.faktor1+9) * this.reihe)) {
      var node = document.querySelector('#feedback10r') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#feedback10f') as HTMLElement;
      node.style.display = 'none';
    } else {
      var node = document.querySelector('#feedback10r') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#feedback10f') as HTMLElement;
      node.style.display = 'block';
    }
    
     
  
  }
  
 
  
  
      
  public level1(){
    this.router.navigate(['/reihen1']);
  }
      
  public level2(){
    this.router.navigate(['/reihen2']);
  }
      
  public level6(){
    this.router.navigate(['/reihen6']);
  }

  public shuffleResultate(){
    this.fisherYatesShuffle(this.resultate)
    
  }

  



  // Funktion, um eine Liste mit Zufall neu zu sortieren
  public fisherYatesShuffle(arr){
    for(var i =arr.length-1 ; i>0 ;i--){
        var j = Math.floor( Math.random() * (i + 1) ); //random index
        [arr[i],arr[j]]=[arr[j],arr[i]]; // swap
    }
  }
} 

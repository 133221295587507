import { Component, OnInit } from '@angular/core';
import { ExerciseTask } from '../../interfaces/exercise-task';
import { FormType } from '../../configuration/form-type';
import { Router } from '@angular/router';
import { TestService } from '../../services/test.service';
import { Result } from 'src/app/interfaces/result';



@Component({
  selector: 'app-exercisetest',
  templateUrl: './exercisetest.component.html',
  styleUrls: ['./exercisetest.component.scss']
})

export class TestclassComponent implements OnInit {

  public task: ExerciseTask;
  public textfield: string;
  public resultat: number;
  public ergebnis: number;
  public plusPunkte: number;
  public minusPunkte: number;
  public actualPunkte: number;
  public numberOperation: number;
  public lastArg1: number;
	public lastOperand: string;
	public lastArg2: number;
  public lastResultat: number;
  public lastTextfield: string;
  public feedback: string
  public name: string;
  public counter: number;
  public punkteNoteSechs: number;
  public punkteNoteVier: number;
  public summandMin: number;
  public summandMax: number;
  public minuendMin: number;
  public minuendMax: number;
  public subtrahendMin: number;
  public subtrahendMax: number;
  public faktorMin: number;
  public faktorMax: number;
  public divisorMin: number;
  public divisorMax: number;
  public actualTaskNumber: number;
  public punkte: number;
  public note: number;

  public valuesAllgemein: FormType[]; 
  public valuesResults: [];

  results: Result[] = [];

    
 
  constructor(
    private router: Router,
    private testService: TestService) { 
  
      }

   
      ngOnInit() {
    this.valuesAllgemein = this.testService.getConfig();
    this.actualTaskNumber = 0;
    this.actualPunkte = 0;
    this.plusPunkte = 0;
    this.minusPunkte = 0;
    this.punkteNoteSechs = 0;
    this.punkteNoteVier = 0;
    this.textfield = '';
    
    
    this.counter = Number(this.valuesAllgemein[0].value)*60;
    this.name = String(this.valuesAllgemein[1].value);
    this.plusPunkte = Number(this.valuesAllgemein[2].value);
    this.minusPunkte = Number(this.valuesAllgemein[3].value);
    this.punkteNoteSechs = Number(this.valuesAllgemein[4].value);
    this.punkteNoteVier = Number(this.valuesAllgemein[5].value);
    this.summandMin = Number(this.valuesAllgemein[6].value);
    this.summandMax = Number(this.valuesAllgemein[7].value);
    this.minuendMin = Number(this.valuesAllgemein[8].value);
    this.minuendMax = Number(this.valuesAllgemein[9].value);
    this.subtrahendMin = Number(this.valuesAllgemein[10].value);
    this.subtrahendMax = Number(this.valuesAllgemein[11].value);
    this.faktorMin = Number(this.valuesAllgemein[12].value);
    this.faktorMax = Number(this.valuesAllgemein[13].value);
    this.divisorMin = Number(this.valuesAllgemein[14].value);
    this.divisorMax = Number(this.valuesAllgemein[15].value);
    this.numberOperation = Number(this.valuesAllgemein[16].value);
    sessionStorage.setItem('Name', (this.name));

    this.createExercise();
    this.counterStart();
    this.startBalken();
             
  }

    // Countdown starten
    public counterStart() {

      var node = document.querySelector('#counterEbene') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#counter') as HTMLElement;
      node.style.visibility = 'visible';
  
      var i = this.counter;
      var myinterval = setInterval(() => { 
        // Umrechnung der Sekunden in Minuten und Sekunden
        var  timenew = Math.floor(i / 60 )+' : '+Math.floor((i % 60));
        document.getElementById("counter").innerHTML = timenew;
        if (i === 0) {
          clearInterval(myinterval );
          this.router.navigate(['/resultstest']);
        }
        else {
          i--;
        }
      }, 1000);
    }
  
    // Countdown Balken starten, Progress negativ 
  public startBalken() {
  
    var node = document.querySelector('#balken') as HTMLElement;
    node.style.visibility = 'visible';
  
    var i = 0;
    if (i == 0) {
      var elem = document.getElementById("balken");
      var width = 100;
      var faktor = this.counter/100*1000;
      var id = setInterval(frame, faktor);
      function frame() {
        if (width <=0) {
          clearInterval(id);
         i = 0 ;
        } else {
          width--;
          elem.style.width = width + "%";
          var node = document.querySelector('#balken') as HTMLElement;
          node.style.display = 'block';
          }
        }
      }
    }
  
  // hier wird die Aufabe erstellt, zuerst Wahl der Operation, dann die Aufgabe per Zufallsgenerator erstellt
  public createExercise() {
      
    // 1 für Addition, 2 für Subtraktion, 3 für Multiplikation 4 für Divsion
    let operation = Math.round(Math.random() * this.numberOperation + 0.5);
   
    // nach Entscheid der Operation zwischen 1 und 4 werden je nach Operation zwei Zufallszahlen gebildet
    // bei der Subtraktion wird verhindert, dass das Resultat negativ wird (könnte noch als Option eingebaut werden )
    // bei der Division wird zuerst der Divisor gebildet, welcher am Ende mit dem Dividenden multipliziert wird, damit es eine Ganzzahl als Ergebnis gibt
    switch (operation) {
      case 1:
      let ersterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
      let zweiterSummand = Math.round(Math.random() * (this.summandMax - this.summandMin)) + this.summandMin;
        this.task = new ExerciseTask(ersterSummand, '+', zweiterSummand);
        this.ergebnis = ersterSummand + zweiterSummand;
      break;
      
      case 2:
        let minuend = Math.round(Math.random() * (this.minuendMax - this.minuendMin)) + this.minuendMin;
        let subtrahend = Math.round(Math.random() * (this.subtrahendMax - this.subtrahendMin)) + this.subtrahendMin;
        if (subtrahend > minuend) {
          let zwischenSpeicher = minuend;
          minuend = subtrahend;
          subtrahend = zwischenSpeicher;
        }
        this.task = new ExerciseTask(minuend, '-', subtrahend);
        this.ergebnis = minuend - subtrahend;
      break;
      
      case 3:
        let ersterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin) ) + this.faktorMin;
        let zweiterFaktor = Math.round(Math.random() * (this.faktorMax - this.faktorMin)) + this.faktorMin;
        this.task = new ExerciseTask(ersterFaktor, '*', zweiterFaktor);
        this.ergebnis = ersterFaktor * zweiterFaktor;
      break;
      
      case 4:
        let divisor = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) + this.divisorMin;
        let dividend = Math.round(Math.random() * (this.divisorMax - this.divisorMin)) * divisor;
        this.task = new ExerciseTask(dividend, ':', divisor);
        this.ergebnis = dividend / divisor;
      break;
      
      default:
        alert("Keine Übereinstimmung");
    }
    
    // notwendige Werte werden im sessionStorage gespeichert
    sessionStorage.setItem('aktuelle Punktzahl', JSON.stringify(this.actualPunkte));
    sessionStorage.setItem('aktuelle Nummer', JSON.stringify(this.actualTaskNumber));
             
  }

  // hier wird nach der Eingabe des Ergebnisses dieses nach click oder Return kontrolliert, die TaskNumber um 1 erhöht, und die nächste Aufgabe aufgerufen
  // zudem werden je nachdem Pluspunkte oder Minuspunkte verrechnet und die aktuelle Note neu berechnet
  // es wird ein Feedback gegeben, dies über 1,5 Sekunden, grün bei richtig gelöster Aufgabe, rot bei falsch gelöster Aufgabe
  public buttonClick() {
    this.lastArg1 = this.task.arg1;
	  this.lastOperand = this.task.operand;
	  this.lastArg2 = this.task.arg2;
    this.lastResultat = this.ergebnis;
    this.lastTextfield = this.textfield;
        
    if (parseFloat(this.textfield) === this.ergebnis) {
      this.actualPunkte = this.actualPunkte  + this.plusPunkte; //wird noch angepasst mit Pluspunkte, geht nicht wegen Typ undefined von Forms zum frei einstellen
      this.actualTaskNumber = this.actualTaskNumber + 1;
      this.feedback = 'Richtig + ' + this.plusPunkte +' Punkte';
      var node = document.querySelector('#richtig1') as HTMLElement;
      node.style.display = 'block';
      var node = document.querySelector('#falsch1') as HTMLElement;
      node.style.display = 'none';
      var node = document.querySelector('#richtig2') as HTMLElement;
      node.style.visibility = 'visible';
      var node = document.querySelector('#falsch2') as HTMLElement;
      node.style.visibility = 'hidden';
      this.berechnungNote();
      setTimeout(this.ausblendenFeedback,1500);
      
      } else {
        this.actualPunkte = this.actualPunkte - this.minusPunkte; //wird noch angepasst mit minusPunkte, geht nicht wegen Typ undefined von Forms zum frei einstellen
        this.actualTaskNumber = this.actualTaskNumber + 1;
        this.feedback = 'Falsch: - '  + this.minusPunkte +' Punkte';
        var node = document.querySelector('#richtig1') as HTMLElement;
        node.style.display = 'none';
        var node = document.querySelector('#falsch1') as HTMLElement;
        node.style.display = 'block';
        var node = document.querySelector('#richtig2') as HTMLElement;
        node.style.visibility = 'hidden';
        var node = document.querySelector('#falsch2') as HTMLElement;
        node.style.visibility = 'visible';
        this.berechnungNote();
        setTimeout(this.ausblendenFeedback,1500);
      }
    
   
    this.createExercise();
    this.textfield = '';
  }

  // Feedback wird nach einer bestimmten Zeit ausgeblendet
  public ausblendenFeedback() {
    var node = document.querySelector('#richtig1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#falsch1') as HTMLElement;
    node.style.display = 'none';
    var node = document.querySelector('#richtig2') as HTMLElement;
    node.style.visibility = 'hidden';
    var node = document.querySelector('#falsch2') as HTMLElement;
    node.style.visibility = 'hidden';
  }
  
  // hiermit wird die aktuelle Note berechnet
  public berechnungNote( ) {

    
        
    // falls jemand Punkte über Maximum hat, soll Note 6 gelten
    if (this.actualPunkte > this.punkteNoteSechs) {
      this.note = 6;
      // console.log('Note Ber. Typ A: ' + note);
      sessionStorage.setItem('aktuelle Note', JSON.stringify(this.note));
     // localStorage.setItem('aktuelle Note', JSON.stringify(note));
    }
    // falls jemand Punkte unter Null hätte, soll Note 1 gelten
    else if (this.actualPunkte < 1) {
      this.note = 1;
      // console.log('Note Ber. Typ B: ' + note);
      sessionStorage.setItem('aktuelle Note', JSON.stringify(this.note));
    //  localStorage.setItem('aktuelle Note', JSON.stringify(note));
    }
    // Punkte unter Punkte für Note sechs, aber über Punkte für Note vier
    else if (this.actualPunkte < this.punkteNoteSechs && this.actualPunkte > this.punkteNoteVier) {
      this.note = (Math.round((this.actualPunkte - this.punkteNoteVier) * 2 / (this.punkteNoteSechs - this.punkteNoteVier) * 10) / 10) + 4;
      // console.log('Note Ber. Typ C: ' + note);
      sessionStorage.setItem('aktuelle Note', JSON.stringify(this.note));
    //  localStorage.setItem('aktuelle Note', JSON.stringify(note));
     
    }
    // Punkte unter Punkte für Note vier, aber über 0 Punkte
    else if (this.actualPunkte < this.punkteNoteSechs && this.actualPunkte > 0) {
       this.note = (Math.round((this.actualPunkte * 3 / this.punkteNoteVier) * 10) / 10) + 1;
      // console.log('Note Ber. Typ D: ' + note);
      sessionStorage.setItem('aktuelle Note', JSON.stringify(this.note));
    //  localStorage.setItem('aktuelle Note', JSON.stringify(note));
    }

  
  
    
  }
// Ende class TestclassComponent
}
